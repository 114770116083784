import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  first_name: string = '';
  last_name: string = '';
  logo_url: SafeUrl = 'https://ui-avatars.com/api/?size=300&name=Hp';
  subscription_data: any;
  timer: any;

  constructor(
    private auth: AuthenticationService,
    private alert: AlertService,
    private router: Router
  ) { }

  validEmail(email: string) {
    const regExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExpression.test(String(email).toLowerCase());
  }

  getProfile() {
    if (
      sessionStorage.getItem('subdomain') != null &&
      sessionStorage.getItem('userID') != null
    ) {
      this.auth.getId('get_user', sessionStorage.getItem('userID')).subscribe(
        (response) => {
          if (response['user'] !== null) {
            const data: any = response['user'];

            this.first_name = data.first_name;
            this.last_name = data.last_name;
            if (data.profile_picture != null)
              this.logo_url = data.profile_picture;
          }
        },
        (error) => {
          // console.log(error);
          // this.alert.warning('Unable to get profile');
        }
      );
    }
  }

  setUserName(): string {
    const username = `${this.first_name} ${this.last_name}`;
    return username;
  }

  captureScreen(id: string): void {
    const printPage = document.getElementById(`${id}`);

    const html = printPage.innerHTML;

    let popupWin = window.open(
      '',
      '_blank',
      'top=0,left=0,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`<html>
    <head>
    <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" />
    <style>

.the-logo {
  width: 100%;
  margin: auto;
}

.logo-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.col-md-4 h6 {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.label-text {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: #666;
  line-height: 1.5em;
}

.block-label {
  display: block;
}

#contentToConvert {
  padding: 2em;
}

td,
th {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.title-text {
  font-size: 1rem;
  margin: 0.8em 0 0;
  text-align: center;
  font-weight: bold;
}

.modal-title {
  font-weight: bold;
}

/***************************************/
.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.logo-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.company-name {
  font-size: 17px;
}

.small-text {
  text-align: right;
  font-size: 13px;
  font-weight: 600;
}

.t-1 {
  font-size: 0.8rem;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left;
}

.modal-title {
  font-weight: bold;
}
@media (min-width: 800px) {
  .the-logo {
    width: 60%;
    margin: auto;
  }
}

.invoice {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}
    </style>
    </head>
    <body onload="window.print()">
    ${html}
    </body>
    </html>`);
    popupWin.document.close();
  }

  getSubscription(): void {
    const data = JSON.parse(sessionStorage.getItem('subscription_data'));

    if (data != null || data != undefined) {
      this.subscription_data = data;
    }
  }

  resetProfile(): void {
    this.first_name = '';
    this.last_name = '';
    this.logo_url = 'https://ui-avatars.com/api/?size=300&name=Hp';
    this.subscription_data = null;
  }

  checkMultipleLogins(): void {
    this.timer = setInterval(() => {
      const formData = new FormData();
      formData.append('', '');
      this.auth.store('check_auth', formData).subscribe(
        (res) => {
          if (res['isRevoked']) {
            sessionStorage.clear();
            this.router.navigate(['/authentication/signin']);
            this.alert.info(
              'Session was closed because a new one was created by you or another user'
            );
            this.stopTimer();
          }
        },
        (err) => {
          // console.log(err);
        }
      );
    }, 10000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  idleLogout(): void {
    var timer;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.onclick = resetTimer; // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments
    let logout = () => {
      this.auth.logout().subscribe(
        (res) => {
          this.resetProfile();
          sessionStorage.clear();
          this.router.navigate(['/authentication/signin']);
          this.stopTimer();
          clearTimeout(timer);
        },
        (err) => {
          // console.log(err);
        }
      );
    };
    function resetTimer() {
      clearTimeout(timer);
      timer = setTimeout(logout, 7200000); // time is in milliseconds
    }
  }


  logout() {
    this.auth.logout().subscribe(
      (res) => {
        // this.utils.resetProfile();
        // // console.log(res);
        sessionStorage.clear();
        this.router.navigate(['/authentication/signin']);
        // Swal.fire('', res['message'], 'success');
        this.alert.success(res['message']);

        this.stopTimer();
      },
      (err) => {
        // console.log(err);
        this.alert.error('Could not log out, please try again');
      }
    );
  }

  refresh(): void {
    window.location.reload();
  }


}
