import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastr: ToastrService,
    // private snackBar: MatSnackBar
    ) {
    this.toastr.toastrConfig.timeOut = 5000;
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
    this.toastr.toastrConfig.closeButton = true;
    this.toastr.toastrConfig.progressBar = true;
    this.toastr.toastrConfig.preventDuplicates = true;
  }

    success(message: string) {
        this.toastr.success(message);
    }
    info(message: string) {
        this.toastr.info(message);
    }
    warning(message: string) {
        this.toastr.warning(message);
    }
    error(message: string) {
        this.toastr.error(message);
    }

  // showNotification(colorName, text, placementFrom, placementAlign) {
  //   this.snackBar.open(text, '', {
  //     duration: 2000,
  //     verticalPosition: placementFrom,
  //     horizontalPosition: placementAlign,
  //     panelClass: colorName
  //   });
  // }
}
