import { UtilityService } from './../services/utility.service';
import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthenticationService, private router: Router, private utility: UtilityService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (this.auth.isLoggedIn()) {
      if ((this.auth.role() == "Patient") || (this.auth.role() == "Client") || (this.auth.role() == "Doctor")) {
        return true;
      } else {
        const subscription_data = JSON.parse(
          sessionStorage.getItem("subscription_data")
        );

        if (!subscription_data.onTrail &&
        subscription_data.subscriptionExpired &&
        !(this.auth.role() == 'Owner' || this.auth.role() == 'Administrator')) {
        // // console.log((this.auth.role() !== 'Super Admin' || this.auth.role() !== 'Administrator')
        // console.log(!subscription_data.onTrail)
        // console.log(subscription_data.subscriptionExpired)
        // console.log(this.auth.role())
        this.utility.logout();
        return false
        }
        return true;
      }
    } else {
      this.router.navigate(['authentication/signin']);
      return false;
    }

  }
}
