import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpHandler } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
// import { AlertService } from './alert.service';
import { interval, Subscription } from 'rxjs';

// const server = 'https://40.121.106.23:8000/api/';
// const server = 'nubiaemr.xyz/api/';
// const server = "nubiaemr.net/api/";

// const server = "nubia.com/api/";
// const server = "nubiaemr.space/api/";
const server = 'nubiaemr.site/api/';
// const paystackURL = 'api.paystack.co/';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // setting token as variable
  token = sessionStorage.getItem("token");

  // Getting and setting domain
  fqdn = sessionStorage.getItem("subdomain");

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  // index of a resource
  get(url) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.get(
      "https://" + sessionStorage.getItem("subdomain") + "." + server + url,
      { headers: config }
    );
  }

  // index of a resource
  getId(url, id) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.get(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      { headers: config }
    );
  }

  // store a new resource
  store(url, payload) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.post(
      "https://" + sessionStorage.getItem("subdomain") + "." + server + url,
      payload,
      { headers: config }
    );
  }

  // store a new resource
  storePay(url, id, payload) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.post(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      payload,
      { headers: config }
    );
  }

  // show a single resource
  show(url, id) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.get(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      { headers: config }
    );
  }

  // show edit details for  a single resource
  edit(url, id) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.get(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      { headers: config }
    );
  }

  // update a single resource
  update(url, id, payload) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.patch(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      payload,
      { headers: config }
    );
  }
  // update a single resource without payload
  update2(url, id) {
    const config = new HttpHeaders();
    config.append("Accept", "application/json");
    config.append("Authorization", "Bearer " + this.token);
    return this.http.patch(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      { headers: config }
    );
  }

  // show patch details for  a single resource
  restore(url, id) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });
    return this.http.patch(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      {},
      { headers: config }
    );
  }

  // delete a particular resource
  destroy(url, id) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.delete(
      "https://" +
        sessionStorage.getItem("subdomain") +
        "." +
        server +
        url +
        "/" +
        id,
      { headers: config }
    );
  }

  // registration of tenants/ clients
  createUser(url, payload) {
    const config = new HttpHeaders();
    config.append("Accept", "application/json");
    return this.http.post("https://" + server + url, payload, {
      headers: config,
    });
  }

  // authentication for user login
  authenticate(payload) {
    const config = new HttpHeaders();
    config.append("Accept", "application/json");
    return this.http.post("https://" + server + "login", payload, {});
  }

  // authentication for user login
  domain(url, payload) {
    const config = new HttpHeaders();
    config.append("Accept", "application/json");
    return this.http.post("https://" + server + url, payload, {});
  }

  // send password reset email
  forgetPassword(url, sub, payload) {
    const config = new HttpHeaders({
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    });

    return this.http.post("https://" + sub + "." + server + url, payload, {
      headers: config,
    });
  }

  // PAYSTACK API CALLS
  getBanks() {
    const config = new HttpHeaders({
      Authorization: "Bearer sk_live_95567acd5dddb46c52e638ee8d5bad940adf65c4",
      // Authorization: "Bearer sk_test_bc35dfe62a5d128402154cb3685ee9f179619879",
      "Content-Type": "application/json",
    });

    return this.http.get(`https://api.paystack.co/bank?currency=GHS`, {
      headers: config,
    });
  }

  createSubaccount(payload) {
    const config = new HttpHeaders({
      Authorization: "Bearer sk_live_95567acd5dddb46c52e638ee8d5bad940adf65c4",
      // Authorization: "Bearer sk_test_bc35dfe62a5d128402154cb3685ee9f179619879",
      "Content-Type": "application/json",
    });

    return this.http.post(`https://api.paystack.co/subaccount`, payload, {
      headers: config,
    });
  }

  updateSubaccount(code, payload) {
    const config = new HttpHeaders({
      Authorization: "Bearer sk_live_95567acd5dddb46c52e638ee8d5bad940adf65c4",
      // Authorization: "Bearer sk_test_bc35dfe62a5d128402154cb3685ee9f179619879",
      "Content-Type": "application/json",
    });

    return this.http.put(
      `https://api.paystack.co/subaccount/` + code,
      payload,
      { headers: config }
    );
  }

  // Get Subaccount Account Name from Paystack
  getName(accountNum, bankCode) {
    const config = new HttpHeaders({
      Authorization: "Bearer sk_live_95567acd5dddb46c52e638ee8d5bad940adf65c4",
      // Authorization: "Bearer sk_test_bc35dfe62a5d128402154cb3685ee9f179619879",
      "Content-Type": "application/json",
    });

    return this.http.get(
      "https://api.paystack.co/bank/resolve?account_number=" +
        accountNum +
        "&bank_code=" +
        bankCode,
      { headers: config }
    );
  }

  /////////////////////////////// GENERAL PRACTICE APIS /////////////////////////
  // For ICD
  getICD(term) {
    const config = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.get(
      "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&maxList=20&terms=" +
        term +
        "&_format=json",
      { headers: config }
    );
  }

  /////////////////////////// LOINC REQUEST /////////////////////////////////////////////
  getLoinc(term) {
    const config = new HttpHeaders({
      Accept: "application/json",
    });

    return this.http.get(
      "https://clinicaltables.nlm.nih.gov/api/loinc_items/v3/search?df=text,LOINC_NUM&maxList=20&terms=" +
        term +
        "&_format=json",
      { headers: config }
    );
  }

  //////////////////////////// RXNORM API GET DRUGS ///////////////////////////////////////////
  // Get the drug products associated with a specified name
  getDrug(value) {
    const config = new HttpHeaders({
      Accept: "application/json",
    });

    return this.http.get("https://rxnav.nlm.nih.gov/REST/drugs?name=" + value, {
      headers: config,
    });
  }

  // get user id
  userID() {
    return sessionStorage.getItem("userID");
  }

  // getting access token
  getToken() {
    return sessionStorage.getItem("token");
  }

  // function to check user type (either customer or staff)
  userData() {
    return sessionStorage.getItem("user");
  }

  // Getting user role
  role() {
    return sessionStorage.getItem("role");
  }

  // check user login
  isLoggedIn() {
    return this.getToken() !== null && this.userData() !== null;
  }

  // // logout method
  // logout() {
  //   sessionStorage.clear();
  //   this.router.navigate(['authentication/signin']);
  // }


  // logout method
  // logout() {
  //   const config = new HttpHeaders({
  //     Authorization: "Bearer " + sessionStorage.getItem("token"),
  //   });

  //   const fqdn = sessionStorage.getItem("subdomain");

  //   const formData = new FormData();
  //   formData.append("", "");

  //   return this.http.post(
  //     "https://" + fqdn + "." + server + "logout",
  //     formData,
  //     {
  //       headers: config,
  //     }
  //   );
  // }
}
